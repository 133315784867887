import { API_URL, BASE_URL, LIBRARY_API, LIBRARY_URL, REPOSITORY_URL } from "../../config/config"
import { setCookieWithDomain, setToken, setUserData } from "../../utils/local-storage"
import { get, post } from "../../utils/request"

export const getPopularBook = async () => {
    try {
        const res = await get(`${LIBRARY_API}/biblio/popular`)
        return res
    } catch(err) {
        throw err
    }
}

export const getLatestBook = async () => {
    try {
        const res = await get(`${LIBRARY_API}/biblio/latest`)
        return res
    } catch(err) {
        throw err
    }
}

export const getMemberTop = async () => {
    try {
        const res = await get(`${LIBRARY_API}/member/top`)
        return res
    } catch(err) {
        throw err
    }
}

export const getSinglePage = async (slug) => {
    try {
        const res = await get(`${API_URL}/page/${slug}`)
        return res
    } catch(err) {
        throw err
    }
}

export const getPostWithCategory = async (category, page, perPage) => {
    try {
        const res = await get(`${API_URL}/posts/${category}?page=${page}&per_page=${perPage}`)
        return res
    } catch(err) {
        throw err
    }
}

export const getResources = async () => {
    try {
        const res = await get(`${API_URL}/resources`)
        return res
    } catch(err) {
        throw err
    }
}

export const getJournals = async () => {
    try {
        const res = await get(`${API_URL}/journals`)
        return res
    } catch(err) {
        throw err
    }
}

export const getBooks = async () => {
    try {
        const res = await get(`${API_URL}/books`)
        return res
    } catch(err) {
        throw err
    }
}

export const getForms = async () => {
    try {
        const res = await get(`${API_URL}/forms`)
        return res
    } catch(err) {
        throw err
    }
}

export const getCooperations = async () => {
    try {
        const res = await get(`${API_URL}/cooperations`)
        return res
    } catch(err) {
        throw err
    }
}

export const getSinglePost = async (slug) => {
    try {
        const res = await get(`${API_URL}/post/${slug}`)
        return res
    } catch(err) {
        throw err
    }
}

export const postRegister = async (payload) => {
    try {
        const res = await post(`${LIBRARY_URL}/kunjungan/api/member/register`, payload)
        return res
    } catch(err) {
        throw err
    }
}

export const getMemberType = async () => {
    try {
        const res = await get(`${LIBRARY_URL}/kunjungan/api/member/type`)
        return res
    } catch(err) {
        throw err
    }
}

export const authentication = async (payload) => {
    try {
        const res = await post(`${LIBRARY_URL}?p=api/auth/login`, payload)

        if(res.status === 200) {
            var data = res.data,
            token = data.token,
            dataCookie = JSON.parse(await window.atob(token.split('.')[1]))
            setCookieWithDomain(dataCookie.name, dataCookie.id, 1, 'e-library.poliwangi.ac.id')
            setToken(token)
            setUserData(JSON.stringify(data.data))
        }

        return res
    } catch(err) {
        throw err
    }
}

export const logout = async () => {
    try {
        const res = await get(`${LIBRARY_URL}?p=api/auth/logout`)
        return res
    } catch(err) {
        throw err
    }
}

export const getStaff = async () => {
    try {
        const res = await get(`${API_URL}/staffs`)
        return res
    } catch(err) {
        throw err
    }
}

export const getCollectStatistic = async () => {
    try {
        const res = await get(`${LIBRARY_API}/collection/count`)
        return res
    } catch(err) {
        throw err
    }
}

export const getRepositoryJournals = async () => {
    try {
        const res = await get(`${REPOSITORY_URL}/rest/getjournals`)
        return res
    } catch(err) {
        throw err
    }
}

export const getRepositoryFinalProjects = async () => {
    try {
        const res = await get(`${REPOSITORY_URL}/rest/getfinalprojects`)
        return res
    } catch(err) {
        throw err
    }
}

export const getRepositoryPenelitian = async () => {
    try {
        const res = await get(`${REPOSITORY_URL}/rest/getpenelitian`)
        return res
    } catch(err) {
        throw err
    }
}

export const getRepositoryPengabdian = async () => {
    try {
        const res = await get(`${REPOSITORY_URL}/rest/getpenelitian`)
        return res
    } catch(err) {
        throw err
    }
}

export const getSearchPost = async (search, page, perPage) => {
    try {
        const res = await get(`${API_URL}/search?page=${page}&per_page=${perPage}&search=${search}`)
        return res
    } catch(err){
        throw err
    }
}