import { createContext, useContext, useEffect, useState } from "react"
import Pace from 'pace-js'
import logo from './../assets/images/poliwangi.png'
import { API_URL } from "../config/config"

const AppContext = createContext()

const AppProvider = ({ children }) => {
    const [configuration, setConfiguration] = useState([])
    const [navbarConfiguration, setNavbarConfiguration] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [contentLoading, setContentLoading] = useState(true)

    const getNavbar = async () => {
        setIsLoading(true)
        setContentLoading(true)
        Pace.restart()
        const res = await fetch(`${API_URL}/settings`, {
            headers: {
                "accept": "application/json"
            }
        })

        setIsLoading(false)
        if (res.status === 200) {
            const data = await res.json()
            setConfiguration(data)
            setNavbarConfiguration(data.menus)
        }
    }

    useEffect(() => {
        getNavbar()
    }, [])

    return (
        <AppContext.Provider value={{ configuration, navbarConfiguration, setContentLoading }} >
            {!isLoading && children}
            <div className={`loader ${ (!isLoading && !contentLoading) ? 'hide' : '' }`}>
                <div className="block w-[120px]">
                    <div className="block">
                        <img src={logo} alt="" />
                    </div>
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    return useContext(AppContext)
}

export { AppProvider, useAppContext }