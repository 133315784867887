import { Link, Outlet, useParams } from "react-router-dom"
import TopBar from "../../components/navbar/top-bar"
import Navbar from "../../components/navbar/navbar"
import { useAppContext } from "../../utils/app-provider"
import { useEffect } from "react"

const ResourceLayout = (props) => {
    const params = useParams()
    const { navbarConfiguration } = useAppContext()

    useEffect(() => { }, [params.slug])

    return (
        <>
            <div className="sticky top-0 z-[9999]">
                <TopBar />
                <Navbar />
            </div>
            <div className="mt-[-6rem] h-[300px] w-100 custom-header flex items-center justify-center relative z-[111]">
                <div className="sm:w-1/3 w-full text-center p-3">
                    <h2 className="font-bold text-3xl uppercase text-white mb-2">e - Resources</h2>
                    <p className="text-sm text-slate-200">Nikmati layanan e - Resources external yang telah disediakan</p>
                </div>
            </div>

            <div className="container mx-auto my-4">
                <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/5 mb-4 p-4 flex-auto">
                        <div className="bg-white shadow-md shadow-slate-400/50 py-2 rounded-md border h-full">
                            <h3 className="font-semibold text-center uppercase text-base my-4">e - Resources</h3>
                            <ul>
                                <li>
                                    <Link to="/resources/journals" className={window.location.pathname === "/resources/journals" ? "p-3 py-2 block text-sm text-blue-900 font-semibold ml-2 transition-all duration-500" : "text-slate-600 p-3 py-2 block text-sm hover:text-blue-900 hover:font-semibold hover:ml-2 transition-all duration-500"}>e - Journal</Link>
                                </li>
                                <li>
                                    <Link to="/resources/books" className={window.location.pathname === "/resources/books" ? "p-3 py-2 block text-sm text-blue-900 font-semibold ml-2 transition-all duration-500" : "text-slate-600 p-3 py-2 block text-sm hover:text-blue-900 hover:font-semibold hover:ml-2 transition-all duration-500"}>e - Book</Link>
                                </li>
                                <li>
                                    <Link to="/resources/forms" className={window.location.pathname === "/resources/forms" ? "p-3 py-2 block text-sm text-blue-900 font-semibold ml-2 transition-all duration-500" : "text-slate-600 p-3 py-2 block text-sm hover:text-blue-900 hover:font-semibold hover:ml-2 transition-all duration-500"}>Formulir Online</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full sm:w-4/5 mb-4 p-4 flex-auto">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResourceLayout