export const setToken = (token) => localStorage.setItem('token', token)
export const getToken = () => localStorage.getItem('token') ?? null
export const setUserData = (userData) => localStorage.setItem('user_data', userData)
export const getUserData = () => localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null
export const setCookieWithDomain = (name, value, days, domain) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    var cookieDomain = domain ? "; domain=" + domain : "";
    document.cookie = name + "=" + (value || "") + expires + "; path=/" + cookieDomain;
}
export const getCookie = (name, domain) => {
    var nameEQ = name + "=";
    var cookies = document.cookie.split(';');
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
        }
    }
    return null;
}

export const deleteCookie = (name, path = '/', domain) => {
    // Set the cookie's expiration date to a past date
    document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}${domain ? `; domain=${domain}` : ''}`;
  }