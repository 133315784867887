import Pace from 'pace-js'

export const get = async (url, props = {}) => {
    try {
        Pace.restart()

        const res = await fetch(url, {
            method: 'get',
            headers: {
                "accept": "application/json",
                ...props?.headers
            }
        })

        var data = await res.json()
        if(res.status === 500) {
            console.log('error')
        }

        if(res.status === 422) {
            console.log('error')
        }

        if(res.status === 400) {
            console.log('error')
        }

        if(res.status > 299) {
            console.log('error')
        }

        return {status: res.status, data: data}
    } catch(err) {
        throw err
    }
}

export const post = async (url, payload, props = {}) => {
    try {
        Pace.restart()

        const res = await fetch(url, {
            method: 'post',
            headers: {
                "accept": "application/json",
                ...props?.headers
            },
            body: generateFormBody(payload)
        })

        var data = await res.json()
        if(res.status === 500) {
            console.log('error')
        }

        if(res.status === 422) {
            return {status: res.status, data: data}
        }

        if(res.status === 400) {
            console.log('error')
        }

        if(res.status > 299) {
            console.log('error')
        }

        return {status: res.status, data: data}
    } catch(err) {
        throw err
    }
}

export const generateFormBody = (payload) => {
    const fd = new FormData()

    payload.forEach((val, key) => {
        fd.append(key, val)
    })

    return fd
}