import { ChevronDown, Edit, LogOut, Menu, User } from 'react-feather'
import logo from './../../assets/images/poliwangi.png'
import { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../utils/app-provider'
import { Link, useNavigate } from 'react-router-dom'
import { deleteCookie, getUserData, setToken, setUserData } from '../../utils/local-storage'
import { LIBRARY_URL } from '../../config/config'
import { logout } from '../../services/collection'

const Navbar = (props) => {
    const navigate = useNavigate()
    const navRef = useRef()
    const { configuration, navbarConfiguration } = useAppContext()
    const [navbarActive, setNavbarActive] = useState(false)
    const [isLogout, setLogout] = useState(false)

    const redirect = (e) => {
        e.preventDefault()
        window.scrollTo(0, 0)
        const url = e.currentTarget.getAttribute('href'),
            urlRegex = /^(https|ftp):\/\/[^\s/$.?#].[^\s]*$/i
        if (!urlRegex.test(url)) {
            navigate(url)
        } else {
            window.open(url, '_blank')
        }
    }

    const handleLogout = async (e) => {
        e.preventDefault()
        const res = await logout()

        if(res.status === 200) {
            setUserData(null)
            setToken(null)
            deleteCookie('SenayanMember')
            window.location.reload()
        }
    }

    useEffect(() => {
        if (window.scrollY > 0) {
            navRef.current.classList.add('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
            setNavbarActive(true)
        } else {
            navRef.current.classList.remove('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
            setNavbarActive(false)
        }

        const handleScroll = () => {
            if (window.scrollY > 0) {
                navRef.current.classList.add('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
                setNavbarActive(true)
            } else {
                navRef.current.classList.remove('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
                setNavbarActive(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [navbarConfiguration])

    return (
        <div className="flex px-8 py-4 w-100" ref={navRef}>
            <div className="flex items-center sm:w-3/12 gap-2 mr-auto">
                <img src={configuration?.site_logo ?? logo} className="sm:w-[55px] w-[45px]" alt="Logo website" />
                <div className={navbarActive ? "text-slate-600" : "text-white"}>
                    <span className="sm:text-xl text-md font-bold tracking-wide mb-0 pb-0 uppercase">Perpustakaan</span>
                    <h1 className="sm:text-sm text-[10px] mt-[-3px] uppercase">Politeknik Negeri Banyuwangi</h1>
                </div>
            </div>
            <div className="flex mx-auto items-center justify-center">
                <ul className="hidden sm:flex items-center gap-5">
                    {
                        navbarConfiguration.map((val, key) => {
                            if (val?.submenu) {
                                return (
                                    <li className={navbarActive ? "group text-slate-600 relative" : "group text-slate-300 relative"} key={key}>
                                        <a href={val?.url} onClick={val?.url && val.url !== '' ? redirect : (e) => e.preventDefault()} className={navbarActive ? "group-hover:text-black text-sm flex gap-2 items-center transition-all" : "group-hover:text-white text-sm flex gap-2 items-center transition-all"}>
                                            {val.label}
                                            <ChevronDown size={16} />
                                        </a>
                                        <div className="hidden group-hover:block w-[220px] p-3 bg-white rounded-lg absolute top-[110%] left-[-10px] transition-all">
                                            <ul>
                                                {
                                                    val.submenu.map((v, k) => (
                                                        <li className="w-100" key={k}>
                                                            <a href={v.url} onClick={redirect} className="text-slate-700 text-sm hover:text-black p-2 block">{v.label}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                )
                            } else {
                                return (
                                    <li className={navbarActive ? "group text-slate-600 relative" : "group text-slate-300 relative"} key={key}>
                                        <a href={val.url} onClick={redirect} className={navbarActive ? "group-hover:text-black text-sm flex gap-2 items-center transition-all" : "group-hover:text-white text-sm flex gap-2 items-center transition-all"}>{val.label}</a>
                                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </div>
            {
                getUserData() !== null ? (
                    <div className={navbarActive ? "hidden sm:flex gap-3 sm:w-1/5 ml-auto text-slate-600 items-center justify-end" : "hidden sm:flex gap-3 sm:w-1/5 ml-auto text-white items-center justify-end"}>
                        <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white border border-slate-400 overflow-hidden">
                            {
                                getUserData()?.member_image !== null ? (
                                    <img src={`${LIBRARY_URL}/${getUserData()?.member_image}`} />
                                ) : (
                                    <img src={`${LIBRARY_URL}/lib/minigalnano/createthumb.php?filename=images%2Fpersons%2Fperson.png&width=120`} />
                                )
                            }
                        </div>
                        <div className="w-auto hidden md:block relative group">
                            <h3 className="font-semibold">{getUserData()?.member_name}</h3>
                            <p className={navbarActive ? "text-xs text-slate-500 mt-[-2px]" : "text-xs text-slate-200 mt-[-2px]"}>{getUserData()?.member_type_name}</p>

                            <div className="absolute top-full right-0 group-hover:block hidden transition-all duration-400 w-[180px] rounded-md bg-white">
                                <a href={`${LIBRARY_URL}/index.php?p=member`} className="text-slate-600 text-base font-light px-3 py-2 flex gap-2 items-center"><User size={14} /> Profile</a>
                                <hr className="mx-3" />
                                <a href="" onClick={(e) => {e.preventDefault();setLogout(true)}} className="text-slate-600 text-base font-light px-3 py-2 flex gap-2 items-center"><LogOut size={14} /> Keluar</a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={navbarActive ? "hidden sm:flex gap-3 sm:w-1/5 ml-auto text-white items-center justify-end" : "hidden sm:flex gap-3 sm:w-1/5 ml-auto text-slate-600 items-center justify-end"}>
                        <Link to="/login" className={navbarActive ? "text-slate-600 hover:text-black text-sm font-medium flex gap-2 items-center transition-all" : "text-slate-300 hover:text-white text-sm font-medium flex gap-2 items-center transition-all"}>
                            <User size={16}></User>
                            Login
                        </Link>
                        |
                        <Link to="/register" className={navbarActive ? "text-slate-600 hover:text-black text-sm font-medium flex gap-2 items-center transition-all" : "text-slate-300 hover:text-white text-sm font-medium flex gap-2 items-center transition-all"}>
                            <Edit size={16}></Edit>
                            Register
                        </Link>
                    </div>
                )
            }
            <div className={`sm:hidden flex gap-3 sm:w-1/5 ml-auto ${navbarActive ? 'text-slate-600' : 'text-slate-100'} items-center justify-end`}>
                <button className={`border ${navbarActive ? 'border-slate-600' : 'border-slate-100'} rounded-md p-2 hover:bg-slate-100/40 transition-all duration-500`}>
                    <Menu size={16} />
                </button>
            </div>

            <div onClick={() => setLogout(false)} className={`${ isLogout ? 'opacity-1' : 'opacity-0 pointer-events-none'} transition-all fixed top-0 left-0 right-0 bottom-0 bg-slate-900/40 flex items-center justify-center`}>
                <div className="w-10/12 md:w-3/12 sm-6/12 bg-white p-5 rounded-lg border shadow-lg shadow-slate-900/50">
                    <h3 className="text-xl font-bold">Keluar?</h3>
                    <hr className="my-4" />
                    <p className="text-base text-slate-800">Apakah anda yakin ingin keluar?</p>
                    <hr className="my-4" />
                    <div className="text-right">
                        <button onClick={() => setLogout(false)} className="px-4 py-1 mx-1 border rounded-md bg-red-600 hover:bg-red-700 transition-all text-white">Tidak</button>
                        <button onClick={handleLogout} className="px-4 py-1 mx-1 border rounded-md bg-blue-900 hover:bg-blue-950 transition-all text-white">Ya, lanjutkan</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar