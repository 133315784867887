import { Outlet } from "react-router-dom"
import TopBar from "../components/navbar/top-bar"
import Navbar from "../components/navbar/navbar"

const MainLayout = (props) => {
    return (
        <>
            <TopBar />
            <div className="sticky top-0 z-[4444]">
                <Navbar />
            </div>
            <Outlet />

            <div className="w-full py-10 bg-white">
                <div className="container mx-auto">
                    <div className="mb-4 flex flex-wrap border bg-white rounded-md overflow-hidden items-center">
                        <iframe className="sm:w-1/3 w-full border" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.0866139664067!2d114.30438101062981!3d-8.294179183388776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd156d7d86bef9b%3A0x4cb09a70b9109740!2sPoliteknik%20Negeri%20Banyuwangi!5e0!3m2!1sid!2sid!4v1715072517793!5m2!1sid!2sid" width="100%" height="350" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="sm:w-2/3 w-full p-[3.5rem]">
                            <span className="font-light text-blue-900 uppercase">Jam Layanan</span>
                            <div className="sm:columns-3 columns-1 my-4 w-full">
                                <div className="sm:mb-0 mb-4">
                                    <strong className="text-slate-700">Senin – Kamis</strong>
                                    <h2 className="font-bold text-2xl text-blue-600">08.00 – 16.00</h2>
                                </div>
                                <div className="sm:mb-0 mb-4">
                                    <strong className="text-slate-700">Jum’at</strong>
                                    <h2 className="font-bold text-2xl text-blue-600">08.00 – 16.30</h2>
                                </div>
                                <div className="sm:mb-0 mb-4">
                                    <strong className="text-slate-700">Sabtu – Minggu</strong>
                                    <h2 className="font-bold text-2xl text-blue-600">Libur</h2>
                                </div>
                            </div>
                            <span className="font-bold text-sm text-slate-700">Keterangan</span>
                            <div className="block w-full text-slate-500 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque dolorem quod nihil, repellendus incidunt quaerat!</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full bg-blue-900 text-slate-200">
                <div className="container mx-auto p-10">
                    <div className="flex flex-wrap">
                        <div className="sm:w-3/12 w-full px-4 mb-3">
                            <span>Perpustakaan Poliwangi</span>
                            <ul className="my-3 ml-[3rem] list-disc">
                                <li>
                                    <a href="/testset">Informasi</a>
                                </li>
                                <li>
                                    <a href="/testse">Pustakawan</a>
                                </li>
                                <li>
                                    <a href="/testse">Member Area</a>
                                </li>
                            </ul>
                        </div>
                        <div className="sm:w-5/12 w-full px-4 mb-3">
                            <h1 className="text-2xl mb-4">Tentang Kami</h1>
                            <p>Unit Perpustakaan merupakan unit yang berupaya untuk memelihara dan meningkatkan efisiensi dan efektifitas proses belajar-mengajar, juga sebagai salah satu sarana penunjang akademik yang memiliki peran cukup strategis dalam penyediaan informasi perkembangan ilmu pengetahuan bagi pengguna di lingkungan civitas akademika Politeknik Negeri Banyuwangi. Unit Perpustakaan menyediakan bahan literasi baik cetak maupun digital untuk menunjang budaya akademis, berfikir kritis, dan literatif.</p>
                        </div>
                        <div className="sm:w-4/12 w-full px-4 mb-3">
                            <h1 className="text-2xl mb-4">Cari</h1>
                            <p>
                                Masukkan satu atau lebih kata kunci dari judul, pengarang, atau subjek
                            </p><div className="flex my-3">
                                <input className="input bg-white px-4 py-3 text-black outline-0 border focus:border-blue-600 rounded-l-md w-full shaodw" />
                                <button className="px-8 py-3 rounded-r-md bg-blue-700 shadow">Cari</button>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainLayout