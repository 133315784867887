import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes';
import reportWebVitals from './reportWebVitals';
import '@fontsource/poppins'
import './assets/css/input.css'
import './assets/css/style.css'
import { AppProvider } from './utils/app-provider';
import Pace from 'pace-js'
import 'pace-js/themes/blue/pace-theme-minimal.css';

Pace.restart()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
