import { Suspense, useEffect } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import logo from './../assets/images/poliwangi.png'
import { useAppContext } from "./app-provider"
import * as Icons from 'react-feather'
import { get } from "./request"
import { getCookie, getToken, setToken, setUserData } from "./local-storage"
import { LIBRARY_URL } from "../config/config"

const Loading = () => {
    const { setContentLoading } = useAppContext()

    useEffect(() => {
        setContentLoading(true)
    })

    return (<></>)
}

const authVerify = async (props) => {
    var opt = {}
    opt.headers = {}
    if(getToken() !== 'null') {
        opt.headers.Authorization = `Bearer ${getToken()}`
    }

    const res = await get(`${LIBRARY_URL}?p=api/auth/verify`, opt)

    if(res.status === 200) {
        if(res.data?.token) {
            setToken(res.data.token)
            setUserData(res.data.data)
        }
    }

    if(res.status === 401) {
        setToken(null)
        setUserData(null)
        // window.location.reload()
    }
    
    if(getCookie('SenayanMember')) {
        if(props?.isHidden) {
            window.location.assign('/')
        }

    }
}

export const CreateComponent = (props) => {
    const Component = props.Component
    const Fallback = props?.fallback ?? Loading
    const { configuration } = useAppContext()

    useEffect(() => {
        authVerify(props)
    }, [])

    if (props.async) {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{props?.title ?? 'Untitled Page'} | { configuration?.site_title ?? '' }</title>
                </Helmet>
                <Suspense fallback={<Fallback />}>
                    <Component {...props} />
                </Suspense>
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{props?.title ?? 'Untitled Page'} | { configuration?.site_title ?? '' }</title>
                </Helmet>
                <Component {...props} />
            </HelmetProvider>
        )
    }
}

export const decodeHTMLEntities = (text) => {
    var textarea = document.createElement('textarea')
    textarea.innerHTML = text
    return textarea.value
}

export const IconComponent = (props) => {
    const Icon = Icons[props.icon]

    return (
        <Icon {...props} />
    )
}